<template>
  <v-main class="main-wrapper">
    <loader :loading="$auth.loading" />
    <base-header :links="links" />
    <v-container>
      <v-row>
        <v-col>
          <slot />
        </v-col>
      </v-row>
    </v-container>
    <dialogs-container />
    <snackbars-container />
    <scroll-to-top />
  </v-main>
</template>

<script>
// TODO COMPANY 2 - przedłuż dostęp do wybranego użytkownika
// TODO COMPANY 2 - możliwość zakupu dodatkowych pakietów z panelu
// TODO COMPANY 2 - możliwość ponownego wysłania linku aktywacyjnego

import BaseHeader from "@/components/base/Header.vue";
import DialogsContainer from "@/components/dialog/DialogsContainer.vue";
import SnackbarsContainer from "@/components/snackbar/SnackbarsContainer.vue";
import Loader from "@/components/base/Loader.vue";
import ScrollToTop from "../components/base/ScrollToTop.vue";

export default {
  name: "CompanyAdmin",
  components: {
    ScrollToTop,
    Loader,
    BaseHeader,
    DialogsContainer,
    SnackbarsContainer,
  },
  computed: {
    links() {
      return [];
    },
  },
};
</script>

<style lang="scss">
.main-wrapper {
  background: #fffaf5;
}
</style>
