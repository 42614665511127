
import Vue from "vue";

export default Vue.extend({
  name: "ScrollToTop",
  data: () => ({
    fab: false,
  }),

  methods: {
    onScroll(e) {
      if (typeof window === "undefined") return;
      const top = window.pageYOffset || e.target.scrollTop || 0;
      this.fab = top > (window.innerHeight || 1000);
    },
    toTop() {
      this.$vuetify.goTo(0);
    },
  },
});
